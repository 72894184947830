@tailwind base;
@tailwind components;
@tailwind utilities;

#mobileNav:not(:checked) {
    & + div {
        display: none;
    }
}

.at-esc {
    display: inline-block;
    height: 1em;
}

.grayscale {
    filter: grayscale(100%);
}

.md-doc {
    & h1 {
        @apply text-2xl font-bold;
    }
    & h2 {
        @apply text-xl font-bold mt-8;
    }
    & h3 {
        @apply text-lg font-bold mt-6; 
    }
    & h4 {
        @apply text-base font-bold mt-5; 
    }
    & h5 {
        @apply text-base italic mt-5; 
    }

    & p {
        @apply my-4;
    }


}
